<template>
  <div class="reply">
    <div class="reply__left">
      <Avatar :name="email?.from" />
    </div>
    <div class="reply__right">
      <div class="reply__section">
        <div class="reply__section-header">
          <PopperDropdown placement="bottom-start" :distance="4" :skidding="-4">
            <div class="reply__action">
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="state === 'reply'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.438 19.5a.562.562 0 01-.494-.293l-.016-.029a9.565 9.565 0 00-8.365-4.928H10.5v3.188a.563.563 0 01-.954.404l-6.375-6.188a.56.56 0 010-.807l6.375-6.188a.563.563 0 01.954.404v3.189c5.807.1 10.5 4.855 10.5 10.685a.562.562 0 01-.563.563z"/></svg>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.563 19.5c.202 0 .392-.11.493-.293l.016-.029a9.565 9.565 0 018.364-4.928H13.5v3.188a.563.563 0 00.954.404l6.375-6.188a.56.56 0 000-.807l-6.375-6.188a.563.563 0 00-.954.404v3.189C7.693 8.352 3 13.107 3 18.937a.562.562 0 00.563.563z"/></svg>
              <!-- prettier-ignore -->
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'reply__action-caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.792 16.97a1 1 0 01-1.584 0L6.31 10.61A1 1 0 017.104 9h9.792a1 1 0 01.793 1.61l-4.897 6.36z"/></svg>
            </div>
            <template #popper="{ hide }">
              <ul class="dropdown__list">
                <li @click="$emit('reply'), hide()">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.438 19.5a.562.562 0 01-.494-.293l-.016-.029a9.565 9.565 0 00-8.365-4.928H10.5v3.188a.563.563 0 01-.954.404l-6.375-6.188a.56.56 0 010-.807l6.375-6.188a.563.563 0 01.954.404v3.189c5.807.1 10.5 4.855 10.5 10.685a.562.562 0 01-.563.563z"/></svg>
                  <p>Reply</p>
                </li>
                <li @click="$emit('forward'), hide()">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.563 19.5c.202 0 .392-.11.493-.293l.016-.029a9.565 9.565 0 018.364-4.928H13.5v3.188a.563.563 0 00.954.404l6.375-6.188a.56.56 0 000-.807l-6.375-6.188a.563.563 0 00-.954.404v3.189C7.693 8.352 3 13.107 3 18.937a.562.562 0 00.563.563z"/></svg>
                  <p>Forward</p>
                </li>
              </ul>
            </template>
          </PopperDropdown>

          <!--          <div class="btn-fullscreen">-->
          <!--            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 4h5a1 1 0 110 2H6.2a.2.2 0 00-.2.2v11.6c0 .11.09.2.2.2h11.6a.2.2 0 00.2-.2V13a1 1 0 112 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2z"/><path d="M19 10a1 1 0 001-1V6a2 2 0 00-2-2h-3a1 1 0 100 2h1.103a.2.2 0 01.141.341l-7.951 7.952a1 1 0 101.414 1.414l7.952-7.951a.2.2 0 01.341.141V9a1 1 0 001 1z"/></svg>-->
          <!--          </div>-->
          <div class="reply__to">
            <label class="reply__to-label">To:</label>
            <EmailToMultiselect v-model="recipients" :options="optionsTo" />
          </div>
        </div>
        <div class="reply__section-body">
          <PerfectScrollbar>
            <div
              ref="emailReplyEdit"
              @input="onInput"
              contenteditable="true"
              style="outline: none"
              class="email-content-styling"
            ></div>
          </PerfectScrollbar>
          <!--          <FveTextarea-->
          <!--              class="test"-->
          <!--              :field="{-->
          <!--                name: '',-->
          <!--                model: true,-->
          <!--              }"-->
          <!--              theme="no-border"-->
          <!--          />-->
        </div>
        <div class="reply__section-footer"  style="flex-wrap: wrap;">
          
          <Dropzone
              class="email-create__attached__wrapper"
              @onDrop="selectFileList"
              v-slot="{ hide, show }"
              style="flex: 0 0 100%;"
          >
            <p class="email-create__attached__title">Attached files</p>
            <input
                type="file"
                ref="inputFile"
                @change="(event) => selectFileList(event.target.files)"
                hidden
                multiple
            />
            <div class="email-create__attached__list">
              <Tag
                  label="Add file(-s)"
                  class="email-create__attached__item"
                  style="cursor: pointer"
                  @click="$refs.inputFile.click()"
                  :key="`attached-item-add-file`"
              >
                <template #icon>
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'email-create__attached__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 5a1 1 0 10-2 0v5a1 1 0 01-1 1H5a1 1 0 100 2h5a1 1 0 011 1v5a1 1 0 102 0v-5a1 1 0 011-1h5a1 1 0 100-2h-5a1 1 0 01-1-1V5z"/></svg>
                </template>
              </Tag>
      
              <!-- <PerfectScrollbar> -->
              <DropzoneDraggable
                  v-model:items="files"
                  @drag-start="show"
                  @drag-end="hide"
              >
                <template #item="{ item, index, remove }">
                  <Tag
                      class="email-create__attached__item"
                      :label="item.filename"
                      :type="item.type"
                      :canClose="true"
                      @close="remove(index)"
                      borderColor
                  />
                </template>
              </DropzoneDraggable>
              <!-- </PerfectScrollbar> -->
            </div>
          </Dropzone>
          
          <FveDateTimePickerViewText
            :field="{ name: 'sendAtTime' }"
            ref="sendAtTime"
          >
            <template #default="{ date, validate }">
              <div class="email-create__dtp">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.445.832l3 2a1 1 0 001.11-1.664L13 11.465V8z"/></svg>
                <transition name="slide-fade" mode="out-in">
                  <template v-if="validate !== null">Delay sending</template>
                  <template v-else>
                    Send at {{ prepareSendAtTimeText(date) }}
                  </template>
                </transition>
              </div>
            </template>
          </FveDateTimePickerViewText>
          <!-- <EmailDelaySending /> -->
          <div class="btn-group">
            <button class="btn btn--outline" @click="onDiscard">Discard</button>
            <button class="btn btn--primary" @click="send">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmailToMultiselect from '@component/Email/EmailToMultiselect';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
// import StylizedMultiselect from "@component/StylizedMultiselect";
import PopperDropdown from '@component/Popper/PopperDropdown';
import Avatar from '@component/Avatar';
// import FveTextarea from "@fve/Element/Text/FveTextarea";
import FveDateTimePickerViewText from '@fve/Element/Date/FveDateTimePickerViewText';
// import EmailDelaySending from "@component/Email/EmailDelaySending";
import ContactClass from '@entity/class/ContactClass';
//
import Dropzone from '@component/Dropzone';
import DropzoneDraggable from '@component/DropzoneDraggable';
import Tag from '@component/Tag';

export default {
  name: 'EmailReply',
  components: {
    Avatar,
    // FveTextarea,
    // EmailDelaySending,
    PopperDropdown,
    EmailToMultiselect,
    FveDateTimePickerViewText,
    PerfectScrollbar,
    // StylizedMultiselect,
    Dropzone,
    DropzoneDraggable,
    Tag,
  },
  props: {
    optionsTo: {
      type: Array,
      default: [],
    },
    content: {
      type: String,
      required: true,
    },
    email: {
      type: Object,
      required: true,
    },
    state: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      recipients: [],
      editorData: '',
  
  
      files: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.emailReplyEdit.focus();
    });
  },
  watch: {
    content: {
      handler: function (current) {
        if (this.editorData) {
          const tmp = document.createElement('div');
          tmp.innerHTML = this.editorData;
          if (tmp.querySelector('.attr')) {
            tmp.querySelector('.attr').remove();
          }
          this.$refs.emailReplyEdit.innerHTML = `
          ${tmp.innerHTML.replace(/(\s*<([^>]+)>)+$/gi, '')}
          <br /><br /><br />
          ${current}
        `;
          return;
        }
        this.$nextTick(() => {
          this.$refs.emailReplyEdit.innerHTML = `
            <br /><br /><br />
            ${current}
          `;
          this.$refs.emailReplyEdit.focus();
        });
      },
      immediate: true,
    },
    state: {
      handler: function (v) {
        if (v === 'reply') {
          this.recipients = [this.email?.from];
        } else {
          this.recipients = [];
        }
      },
      immediate: true,
    },
  },
  computed: {
    // currentUserToContact() {
    //   return new ContactClass(USER.Profile.getProfileObj());
    // },
  },
  methods: {
  
    async selectFileList(files) {
      const fileList = Array.from(files);
    
      try {
        for (let i = 0; i < fileList.length; i++) {
          const fileObj = await RequestManager.FileStorage.add({
            file: fileList[i],
          });
          this.files = this.files.concat({
            filename: fileObj.filename,
            bucketname: fileObj.bucketname,
            url: fileObj.url,
          });
        }
      } catch (err) {}
    
      // this.files = this.files.concat(
      //   Array.from($event.target.files)
      // );
    },
    
    
    onInput(e) {
      this.editorData = e.target.innerHTML;
    },
    onDiscard() {
      this.send(this.$enum.EMAIL_FOLDER_NAME.DRAFT);
      this.$emit('discard');
    },
    prepareSendAtTimeText(date) {
      return DateTime(date).format('MM/DD/YY hh:mm A');
    },
    async send(/* folderName */) {
  
      // const { EMAIL_FOLDER_NAME } = this.$enum;
      // const folderName = EMAIL_FOLDER_NAME?.[this.tag.toUpperCase()];
      const folderName = '';
      
      try {
        
        let sendAtTime = null;
        try {
          const sendAtTimeObj = await this.$refs.sendAtTime.fieldSubmit();
          this.sendAtTime = sendAtTimeObj?.data || null;
          sendAtTime = this.sendAtTime ? DateTime(this.sendAtTime).toISOString() : null;
        } catch (e) {
          sendAtTime = null;
          console.warn(e);
        }

        const data = {
          recipients: this.recipients,
          from: USER.Profile.getEmail(),
          subject:
            (this.state === 'reply'
              ? 'RE: '
              : this.state === 'forward'
              ? 'FW:'
              : '') + (this.email?.subject ?? ''),
          body: this.editorData,
          ownerCommunityId: this.email?.ownerCommunityId || null,
          ownerCommunityName: this.email?.ownerCommunityName || null,
          sendAtTime: sendAtTime,
  
          files: this.files || [],
          seen: true,
        };

        if (!folderName) {
          RequestManager.Email.send({
            delayedSend: !!data.sendAtTime,
            data,
          }).then(() => {
            this.$router.push({ name: this.$routeName.EMAIL });
            this.$dialog.notify.success('Success', 'Your reply has been sent!');
          });
        } else {
          RequestManager.Email.add({ ...data, folder: folderName }).then(() => {
            this.$router.push({ name: this.$routeName.EMAIL });
            this.$dialog.notify.warning('Attention', 'Your letter save to drafts!');
          });
        }
      } catch (err) {
        console.error(err);
      }
      // if (this.email) {
      //   RequestManager.Email.add(this.email).then(() => {
      //     this.$router.push({ name: this.$routeName.EMAIL });
      //     this.$dialog.notify("Success", "Tag changed successfully!");
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ps) {
  max-height: 240px;
  // height: 100%;
}
.dropdown__list {
  li {
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    svg {
      @include icon-size(1);
      margin-right: 4px;
    }
  }
}
.reply {
  display: flex;
  flex-direction: row;

  .reply__left {
    display: flex;
    & > .cm {
      @include icon-size(2);
      margin: 8px 8px 0 0;
    }
  }

  .reply__right {
    display: flex;
    flex: 1;
  }

  .reply__section {
    background-color: var(--color-gray-01);
    border: 1px solid var(--color-gray-03);
    box-shadow: var(--modal-shadow);
    border-radius: 8px;
    padding: 16px;
    flex: 1 1 auto;
  }

  .reply__to {
    display: flex;
    align-items: center;
    flex: 1;
    .reply__to-label {
      margin-right: 4px;
    }
  }
  .reply__action {
    display: flex;
    margin-right: 4px;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    &:hover {
      background-color: var(--color-gray-02);
    }
    &--selected {
      background-color: var(--color-gray-03);
    }
    & > svg {
      fill: var(--color-gray-04);
    }

    .reply__action-caret {
      @include icon-size(1);
    }
  }

  .reply__section-header {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    width: 100%;
    margin-top: -4px;
    margin-left: -4px;
  }

  .reply__section-body {
    margin-bottom: 28px;
  }

  .reply__section-footer {
    display: flex;
    justify-content: space-between;
  }
}

.email-create__datepicker {
  --rounded-full: 8px;
  --blue-600: var(--color-secondary);
  --gray-900: var(--color-gray-06);
  // box-shadow: 6px 20px 36px rgba(6, 18, 55, 0.04);
  border: 0;
  ::v-deep(.vc-title) {
    font-size: 0.875rem;
    font-weight: normal;
    font-family: var(--font-family);
  }
  ::v-deep(.vc-weekday) {
    color: var(--color-gray-06);
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.625rem;
  }
  ::v-deep(.vc-day-content) {
    font-size: 0.875rem;
  }
}

.email-create__link {
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    @include icon-size(1.5);
    fill: var(--color-secondary-light);
  }
}
.email-create__link a {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0 8px;
  line-height: 150%;
  text-decoration: underline;
  color: var(--color-secondary-light);
}

.btn-fullscreen {
  margin-left: auto;
  cursor: pointer;
  & > svg {
    fill: var(--color-gray-04);
  }
}



.email-create__attached__wrapper {
  // display: flex;
  margin: 0 0;
  padding: 5px 0 16px 0;
  position: relative;
}

.email-create__dropzone {
  margin: 24px 32px;
  width: -webkit-fill-available;
  // position: absolute;
}
.email-create__attached__title {
  color: var(--color-gray-06);
  margin-bottom: 0.5rem;
}
.email-create__attached__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px -8px;
  align-items: flex-start;
}

.email-create__attached__item {
  margin: 0 4px 8px;
  user-select: none;
}

.email-create__attached__icon {
  @include icon-size(1.25);
  fill: var(--color-secondary-light);
}
</style>
