<template>
  <div>
    <EmailAction
      @delete="deleteEmail"
      @setUnread="setUnread"
      @setSpam="moveTo($enum.EMAIL_FOLDER_NAME.SPAM)"
      @moveTo="moveTo"
      @reply="onReply"
      @forward="onForward"
      :view="{ delete: true, markUnread: true, spam: true }"
      :date="prepareDate"
    />
    <div class="email-read">
      <div class="email-read__header">
        <div class="email-read__title">{{ email?.subject }}</div>

        <div class="email-read__info">
          <div class="email-read__from">{{ email?.from ? 'From:' : '' }} {{ email?.from }}</div>
            <!--
            <div class="email-read__from-subinfo">
              To
              <div class="email-read__to" @click="toggleSubinfo">
                me
                <template v-if="isSubinfoToggled">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.707 15.707a1 1 0 01-1.414 0L12 9.414l-6.293 6.293a1 1 0 01-1.414-1.414l7-7a1 1 0 011.414 0l7 7a1 1 0 010 1.414z"/></svg>
                </template>
                <template v-else>
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.293 8.293a1 1 0 011.414 0L12 14.586l6.293-6.293a1 1 0 111.414 1.414l-7 7a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414z"/></svg>
                </template>
              </div>
              from
              <div class="email-read__from-community">
                {{ email.ownerCommunityName }}
              </div>
            </div>
            -->
          </div>
    
          <!--
          <template v-if="isSubinfoToggled && email.recipients?.length">
            <div class="email-read__subinfo">
              <p>Also to</p>
              <template v-for="(item, index) in email.recipients" :key="index">
                <p class="email-read__subinfo-to">{{item}}{{ ((index+1) !== email.recipients.length) ? ',' : ''}}</p>
              </template>
            </div>
  
            <div class="email-read__counter">
              <span class="email-read__readed">{{email.recipients?.length || 0}}</span>/{{email.recipients?.length || 0}} read the letter
            </div>
          </template>
          -->
      </div>
      <div class="email-read__body">
        <div class="ps-wrapper">
          <PerfectScrollbar style="flex-direction: column">
            <div class="attachments">
              <template v-for="(file, i) in email.files" :key="i">
                <Tag
                  class="attachment"
                  :label="file.filename"
                  @click="fileStorageGetPreview(file)"
                />
              </template>
            </div>
            <div class="email-read__text email-content-styling">
              <div v-html="email?.body"></div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>

      <div class="email-read__footer">
        <template v-if="!isEditorVisible">
          <div class="btn-reply" @click="onReply">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20.438 19.5a.562.562 0 01-.494-.293l-.016-.029a9.565 9.565 0 00-8.365-4.928H10.5v3.188a.563.563 0 01-.954.404l-6.375-6.188a.56.56 0 010-.807l6.375-6.188a.563.563 0 01.954.404v3.189c5.807.1 10.5 4.855 10.5 10.685a.562.562 0 01-.563.563z"/></svg>
            <span>Reply</span>
          </div>
        </template>

        <template v-else>
          <EmailReply
            :content="editorContentGenerated"
            :email="email"
            :state="editorState"
            :optionsTo="optionsTo"
            @reply="onReply"
            @forward="onForward"
            @discard="isEditorVisible = false"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import EmailAction from "@component/Email/EmailAction";
import EmailReply from "@component/Email/EmailReply";
import Tag from "@component/Tag";
import { fileStorageGetPreview } from "@store/fileStorage.store";
import { communityGetList } from "@store/community.store";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { merge } from "lodash";

export default {
  name: "EmailRead",
  components: {
    EmailAction,
    EmailReply,
    PerfectScrollbar,
    Tag,
  },
  data() {
    return {
      communityList: communityGetList(),
      // sendReply: false,
      editorState: null,
      isEditorVisible: false,
      isSubinfoToggled: false,
      email: {},
      editorContentGenerated: "",
    };
  },
  created() {
    this.fetchEmail();
  },
  computed: {
    optionsTo() {
      const contactList = this.communityList.reduce(
        (result, { participants }) => {
          result = merge(participants, result);
          return result;
        },
        []
      );
      // return contactList;

      return contactList.map(({ fullname, mail }) => ({
        fullname,
        mail,
        combinedField: fullname !== mail ? fullname + mail : mail,
      }));
    },

    prepareDate() {
      return DateTime(this.email?.date).format("MM/DD/YY hh:mm A");
    },
  },
  methods: {
    fileStorageGetPreview,
    onForward() {
      const state = "forward";
      this.isEditorVisible = true;
      // if (this.editorState === state) return;

      this.editorState = "forward";

      const dateTime = DateTime(this.email?.date).format("MM/DD/YY hh:mm A");

      this.editorContentGenerated = `
        <div class="attr">
          ---------- Forwarded message ---------
          <br />
          From:    &lt;${this.email.from}&gt; <br />
          Date:    ${dateTime}                <br />
          Subject: ${this.email.subject}      <br />
          To:      ${this.email.recipients}   <br />
          <br />
          <div style="border: 1px solid var(--color-gray-03); padding: 16px; border-radius: 4px;">
          ${this.email.body}
          </div>
        </div>
      `;
    },
    onReply() {
      const state = "reply";
      this.isEditorVisible = true;
      // if (this.editorState === state) return;

      this.editorState = "reply";

      const dateTime = DateTime(this.email?.date).format("MM/DD/YY hh:mm A");

      this.editorContentGenerated = `
        <div class="attr">
          On ${dateTime} &lt;${this.email.from}&gt; wrote:<br />
          <br />
          <div style="border: 1px solid var(--color-gray-03); padding: 16px; border-radius: 4px;">
          ${this.email.body}
          </div>
        </div>
      `;
    },

    toggleSubinfo() {
      this.isSubinfoToggled = !this.isSubinfoToggled;
    },

    fetchEmail() {
      RequestManager.Email.getEmailById({ id: this.$route.params.id }).then(
        (res) => {
          this.email = res;
          this.setReadState();
        }
      );
    },

    deleteEmail() {
      RequestManager.Email.deleteEmailById({
        id: this.email.id,
      }).then(() => {
        this.$router.push({ name: this.$routeName.EMAIL });
        this.$dialog.notify.success("Success", "Tag changed successfully!");
      });
    },

    setReadState() {
      // this.$route
      RequestManager.Email.setRead({ emailIds: [this.email.id] }).then(() => {
        return;
      });
    },

    setUnread() {
      RequestManager.Email.setUnread({ emailIds: [this.email.id] }).then(() => {
        return;
      });
    },

    moveTo(folder) {
      RequestManager.Email.moveToFolder({
        emailIds: [this.email.id],
        folderName: folder,
      }).then(() => {
        this.$router.push({ name: this.$routeName.EMAIL });
        this.$dialog.notify.success("Success", "Tag changed successfully!");
      });
      // RequestManager.Email.setTag({ emailIds: [this.email.id], folder }).then(
      //   () => {
      //     this.$router.push({ name: this.$routeName.EMAIL });
      //     this.$dialog.notify("Success", "Tag changed successfully!");
      //   }
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
.ps-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & > .ps {
    flex: 1;
    display: flex;
  }
}

.email-read {
  // padding: 20px 32px;
  background-color: var(--color-gray-01);
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;

  .email-read__header {
    display: flex;
    flex-direction: column;
    padding: 20px 32px;
    border-bottom: 1px solid var(--color-gray-03);
  }

  .email-read__header-left {
    display: flex;
    flex-direction: column;
  }

  .email-read__title {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color-gray-07);
    font-weight: 600;
    margin-bottom: 20px;
  }

  .email-read__info {
    display: flex;
    flex-direction: column;
  }

  .email-read__from {
    font-size: 0.75rem;
    color: var(--color-gray-07);
    line-height: 1.35;
    font-weight: 600;
  }

  .email-read__from-subinfo {
    display: flex;
    font-size: 0.75rem;
    line-height: 1.35;
  }

  .email-read__to {
    color: var(--color-gray-07);
    font-weight: 600;
    margin: 0 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    & > svg {
      fill: var(--color-gray-04);
      width: 16px;
      height: 16px;
    }
  }

  .email-read__from-community {
    color: #03a9f4;
    margin-left: 4px;
  }

  .email-read__header-center {
    display: flex;
    flex: 1 1 auto;
    padding: 0 20px;
  }

  .email-read__header-right {
    display: flex;
    align-items: flex-start;
  }

  .email-read__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    padding: 24px 32px;
    border-bottom: 1px solid var(--color-gray-03);
  }

  .email-read__footer {
    padding: 24px 32px;
  }

  .email-read__text {
    p {
      margin-bottom: 15px;
    }
  }
  .email-read__subinfo {
    color: var(--color-gray-05);
    font-size: 0.75rem;
    line-height: 1.35;
    display: flex;
    margin-top: 8px;
  }

  .email-read__subinfo-to {
    font-weight: 700;
    margin-left: 4px;
  }

  .email-read__counter {
    display: flex;
    color: var(--color-secondary-light);
    font-size: 0.75rem;
  }

  .email-read__readed {
    color: var(--color-gray-05);
  }
}

.email-item__user-role {
  display: inline-block;
  padding: 4px 16px;
  line-height: 16px;
  border-radius: 4px;
  font-size: 12px;
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  grid-auto-flow: column;
  grid-template-columns: min-content;
  width: fit-content;
  margin: -0.5rem -0.5rem 0.75rem;
}

.attachment {
  white-space: nowrap;
  cursor: pointer;
  margin: 0.5rem 0.5rem 0.25rem;
  // display: inline-flex;
  // align-items: center;
  // border-radius: 4px;
  // border: 1px solid var(--color-gray-03);
  // padding: 1px 8px;
  // font-size: 12px;
  // line-height: 1.35;
  // margin-right: 8px;
  // &:last-of-type {
  //   margin-right: 0;
  // }
  // & > span {
  //   margin-left: 8px;
  // }
  // & > svg {
  //   width: 20px;
  //   height: 20px;
  // }
}

.btn-reply {
  padding: 16px;
  background-color: var(--color-background);
  color: var(--color-gray-04);
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;
  & > svg {
    margin-right: 4px;
    fill: var(--color-gray-04);
  }

  &:hover {
    background-color: var(--color-gray-03);
  }
}

// TODO: Test badges, remove it later!

.badge-01 {
  background-color: #c4f3f0;
  color: #1bc0b7;
}

.badge-02 {
  background-color: #e6f7b7;
  color: #9ccb18;
}

.badge-03 {
  background-color: #f7b7b7;
  color: #cb1818;
}
</style>
